.page-hero {
  @include overlay;
  background-color: $hero-3;
  background-image: linear-gradient(142deg, $hero-1, $hero-2 31%, $hero-3 65%, $hero-4);
  position: fixed;
  transform: translateZ(0); }

.page-hero-pattern {
  @include overlay;
  background: rgba(black, .1);
  content: "";
  display: block;
  mix-blend-mode: multiply;
  opacity: 0;
  @include from(700px) {
    background: url("/images/pattern.png") repeat;
    mix-blend-mode: multiply; } }

.page-body {
  position: relative; }

.page-header {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  .container {
    border-bottom: 1px solid rgba(black, .1);
    box-shadow: 0 1px 0 rgba(white, .1);
    height: $height-header; }
  .share-buttons {
    position: absolute;
    right: 20px;
    top: 24px; }
  %share-button {
    position: static;
    margin: 0 0 0 18px; }
  @include desktop {
    .share-buttons {
      right: 0; } } }

.page-header-icons {
  padding: 18px 0;
  text-align: center;
  text-shadow: 0 2px 3px rgba(black, .3);
  a {
    color: $yellow;
    display: inline-block;
    height: 32px;
    margin: 0 5px;
    vertical-align: top;
    width: 32px;
    &:hover {
      color: white;
      @include transform(scale(1.2)); }
    &:active {
      text-shadow: none;
      @include transform(scale(1.1)); } }
  .fa {
    @include fa(21px, 32px); }
  @include mobile {
    display: none; } }

.page-container {
  padding-top: $height-header; }

.page-content {
  line-height: 1.6;
  .container {
    background: white;
    @include desktop {
      border-radius: 3px 3px 0 0;
      box-shadow: 0 30px 60px rgba(black, .3); } } }

.ff {
  background-color: $beige;
  padding: 30px;
  position: relative;
  text-align: center;
  @include desktop {
    padding: 60px 40px 80px; } }

.ff-title {
  color: $dark;
  font-size: 2em;
  font-weight: bold;
  line-height: 1.25;
  margin-bottom: 1em; }

.ff-content {
  font-size: 1.125rem;
  margin: 0 auto 2em;
  max-width: 480px; }

.ff-cover {
  display: inline-block;
  vertical-align: top; }

.ff-cta {
  margin-top: 1em; }

.ff-cta a {
  @extend %button;
  background-color: $fortyfour;
  border: none;
  color: #fff;
  font-size: 1.25em;
  font-weight: bold;
  padding: 0.75em 1.5em; }

.page-newsletter {
  background-color: $red;
  background-image: linear-gradient(142deg, adjust-hue($red, -20deg), adjust-hue($red, -10deg) 31%, $red 65%, adjust-hue($red, 10deg));
  color: $yellow;
  padding: 30px;
  position: relative;
  strong {
    color: inherit; }
  @include desktop {
    padding: 60px 40px 80px; } }

.page-newsletter-heading {
  position: relative;
  text-align: center;
  text-shadow: 0 1px 2px rgba(black, .2);
  .icons {
    display: block;
    height: 120px;
    margin: 0 auto;
    position: relative;
    width: 120px;
    .fa {
      @include fa(42px, 120px);
      @include overlay; }
    .fa-television {
      font-size: 84px; }
    .fa-play-circle {
      color: white;
      left: -3px;
      top: -6px; } }
  h3 {
    color: white;
    font-size: 32px;
    line-height: 1;
    position: relative; }
  p {
    font-size: 18px; }
  @include desktop {
    h3 {
      font-size: 40px; }
    p {
      font-size: 20px;
      margin-top: 5px; } } }

.page-newsletter-form {
  margin: 30px auto 0;
  max-width: 480px; }

.page-newsletter-group {
  position: relative;
  .fa {
    color: $light;
    font-size: 14px;
    pointer-events: none;
    position: absolute;
    left: 10px;
    top: 10px; }
  label.error {
    cursor: default;
    display: block;
    font-size: 14px;
    margin: 5px 0 10px; }
  @include desktop {
    padding-right: 100px; } }

.page-newsletter-input {
  border: none;
  border-radius: $radius;
  display: block;
  outline: none;
  padding: 6px 12px 8px 32px;
  width: 100%;
  @include mobile {
    margin-bottom: 10px; } }

.page-newsletter-button {
  @extend %button--dark;
  &.loading {
    background: $dark;
    border-color: transparent;
    box-shadow: none;
    color: rgba(white, 0.3);
    cursor: progress; }
  @include desktop {
    position: absolute;
    right: 0;
    top: 0; } }

.page-footer {
  background: $dark;
  position: relative;
  text-shadow: 0 1px 2px rgba(black, .2);
  .container {
    padding: 30px; }
  .share-buttons {
    margin: 30px 0; }
  a {
    color: $link;
    &:hover {
      text-decoration: underline; } }
  strong {
    color: white; }
  form {
    color: white;
    font-size: 16px;
    margin-bottom: 30px;
    strong {
      color: #89ff6b;
      &:first-child {
        color: white; } }
    em {
      display: block;
      font-size: 14px;
      font-style: normal;
      margin-bottom: 10px; }
    p {
      margin-top: 0; } }
  @include mobile {
    form {
      text-align: center; }
    p {
      margin-top: 1em; } }
  @include desktop {
    .container {
      padding: 60px 0; }
    %share-button {
      left: auto;
      margin: 0 20px 0 0;
      position: relative;
      right: auto; } } }

.page-overlay {
  @include overlay;
  background: rgba(black, .7);
  display: none;
  position: fixed; }

.page-menu {
  @include overlay;
  -webkit-overflow-scrolling: touch;
  bottom: 78px;
  display: none;
  min-height: calc(100vh - 30px);
  overflow: scroll;
  overflow-x: hidden;
  position: fixed;
  @include desktop {
    bottom: 0;
    box-shadow: 0 30px 60px rgba(black, .86);
    left: 50%;
    margin-left: -480px;
    right: auto;
    position: fixed;
    width: 960px; }
  @include from(1200px) {
    margin-left: -540px;
    width: 1080px; }
  @include from(1400px) {
    margin-left: -570px;
    width: 1140px; } }

.page-close {
  @include overlay;
  background: $background;
  box-shadow: inset 0 2px 3px rgba(black, .05), inset 0 1px 0 rgba(black, .05);
  display: none;
  padding: 15px;
  position: fixed;
  top: auto;
  @include desktop {
    display: none; } }

.page-close-button {
  @extend %button--primary;
  display: block;
  padding: 15px;
  text-align: center; }

html.has-menu-open {
  overflow: hidden;
  .page-overlay,
  .page-menu,
  .page-close {
    display: block; }
  @include desktop {
    .page-close {
      display: none; } } }

.share-buttons {
  height: 20px;
  overflow: hidden;
  position: relative;
  .twitter-share-button {
    position: absolute;
    &,
    &:visited {
      color: transparent; } } }

%share-button {
  color: transparent;
  display: inline-block;
  font-size: 14px;
  line-height: 20px;
  margin: 0 10px;
  position: absolute;
  text-shadow: none;
  top: 0;
  vertical-align: top;
  a {
    &,
    &:visited {
      color: transparent; } } }

.share-button--twitter {
  @extend %share-button;
  right: 50%; }

.share-button--facebook {
  @extend %share-button;
  left: 50%; }

.fb_iframe_widget {
  display: inline-block;
  vertical-align: top;
  span {
    display: inline-block;
    vertical-align: top !important; } }

.toc-head {
  height: 40px;
  margin-top: -40px;
  .container {
    background-color: rgba(black, .2);
    background-image: linear-gradient(rgba(black, 0), rgba(black, .2));
    border-radius: 3px 3px 0 0;
    box-shadow: inset 0 -3px 3px rgba(black, .1), 0 -1px 0 rgba(white, .1);
    height: 40px; }
  @include mobile {
    display: none; } }

.toc-column {
  @include replace(url("/images/toc-head.png"), 240px, 40px);
  position: absolute;
  top: 0;
  width: 80px; }

.toc-column--sections {
  @extend .toc-column;
  background-position: 0 0;
  left: 20px; }

.toc-column--chapters {
  @extend .toc-column;
  background-position: -80px 0;
  left: 265px; }

.toc-column--lessons {
  @extend .toc-column;
  background-position: -160px 0;
  left: 480px; }

.toc-component {
  position: relative;
  .container {
    background: white; }
  @include desktop {
    .container {
      box-shadow: 0 20px 40px rgba(black, .2); } } }

.toc-section {
  min-height: 120px;
  position: relative; }

.toc-heading {
  background: $dark;
  padding: 25px;
  .fa {
    @include fa(21px, 25px);
    left: 15px;
    position: absolute; }
  h3 {
    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    line-height: 1;
    a {
      color: white;
      &:hover {
        text-decoration: underline; } } }
  small {
    color: $light;
    display: block; }
  em {
    display: block;
    font-style: normal;
    line-height: 1.2;
    margin-top: 10px;
    strong {
      color: $light; } }
  @include mobile {
    .fa {
      display: none; } }
  @include desktop {
    @include overlay;
    border-bottom: 1px solid lighten($dark, 10%);
    padding-left: 50px;
    right: auto;
    width: 240px; } }

.toc-content {
  padding: 15px;
  position: relative;
  li {
    display: block; }
  a {
    border: 1px solid transparent;
    border-radius: 3px;
    display: block;
    padding: 5px 10px 7px;
    strong {
      font-weight: 400; }
    &:visited {
      color: $visited;
      strong {
        color: $visited; } }
    &:hover {
      background: linear-gradient(white, $background);
      border-color: $border-dark;
      box-shadow: 0 1px 2px rgba(black, .07); }
    &:active,
    &:focus {
      background: $background;
      box-shadow: none; } }
  .toc-month {
    a {
      &,
      &:hover {
        color: $strong;
        font-weight: 700;
        em {
          font-style: normal; } } } }
  @include desktop {
    border-bottom: 1px solid $border;
    margin-left: 240px;
    padding: 20px 10px;
    li {
      a {
        padding-left: 230px; } }
    .toc-month {
      left: 10px;
      position: absolute;
      width: 220px;
      z-index: 1;
      a {
        padding-left: 10px; } } } }

.toc-section--web {
  .fa,
  .toc-heading small span,
  .toc-content .toc-month em {
    color: $web; } }

.toc-section--html5 {
  .fa,
  .toc-heading small span,
  .toc-content .toc-month em {
    color: $html5; } }

.toc-section--css3 {
  .fa,
  .toc-heading small span,
  .toc-content .toc-month em {
    color: $css3; } }

.toc-section--sass {
  .fa,
  .toc-heading small span,
  .toc-content .toc-month em {
    color: $sass; }
  .toc-heading,
  .toc-content {
    border-bottom: none; } }
