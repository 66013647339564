.container {
  @include clearfix;
  margin: 0 auto;
  max-width: 960px;
  position: relative;
  @include from(1200px) {
    max-width: 1080px; }
  @include from(1400px) {
    max-width: 1140px; } }

.banner {
  background-color: $red;
  // background-image: linear-gradient(142deg, adjust-hue($red, -20deg), adjust-hue($red, -10deg) 31%, $red 65%, adjust-hue($red, 10deg))
  display: block;
  color: $yellow;
  padding: 15px;
  position: relative;
  text-align: center;
  &:before {
    @include overlay;
    background: black;
    content: "";
    display: block;
    opacity: 0;
    transition: opacity 86ms ease-out; }
  .fa,
  strong,
  em {
    color: $yellow;
    display: inline-block;
    line-height: 20px;
    margin: 0 5px;
    position: relative;
    transition: color 86ms ease-out;
    vertical-align: top; }
  .fa {
    font-size: 14px;
    position: relative;
    top: 1px; }
  strong {
    color: white; }
  em {
    font-style: normal; }
  &:hover {
    &:before {
      opacity: 0.1; }
    .fa,
    strong,
    em {
      color: white; } } }

.message {
  background-color: $fortyfour; }

.hello {
  @include replace(url("/images/marksheet-hello.png"), 210px, 192px);
  margin: 0 auto;
  @include from(600px) {
    @include replace(url("/images/marksheet-hello.png"), 420px, 384px); } }

.logo {
  @include replace(url("/images/marksheet-logo.png"), 230px, 48px);
  float: left;
  left: 10px;
  position: absolute;
  top: 10px;
  @include mobile {
    background-position: left center;
    width: 48px; }
  @include desktop {
    left: 0; } }

.start {
  @include replace(url("/images/button-start-reading.png"), 243px, 64px);
  background-position: 0 0;
  margin: 60px auto 0; }

.heading {
  color: white;
  line-height: 1.2;
  text-align: center;
  text-shadow: 0 2px 3px rgba(black, .3);
  .container {
    padding: 60px 30px; }
  strong {
    color: inherit; }
  h1,
  h2 {
    font-weight: 300; }
  h1 {
    color: $yellow;
    font-size: 20px;
    em {
      font-style: normal;
      font-weight: 300; } }
  h2 {
    font-size: 48px;
    margin-bottom: 20px;
    margin-top: 20px; }
  @include desktop {
    h1 {
      font-size: 32px; }
    h2 {
      font-size: 60px; }
    .container {
      padding: 120px; } } }

.breadcrumb-holder {
  height: 40px;
  position: relative; }

.breadcrumb {
  background-color: $yellow;
  background-image: linear-gradient(#fffde9, $yellow);
  box-shadow: inset 0 1px 0 white, inset 0 -3px 5px rgba(black, .05);
  height: 40px;
  text-shadow: 0 1px 0 white;
  will-change: scroll-position;
  &.affix {
    position: fixed !important; }
  &.affix-bottom {
    position: absolute !important; }
  &.affix,
  &.affix-bottom {
    box-shadow: 0 3px 5px rgba(black, .1), 0 1px 0 rgba(black, .1);
    top: 0;
    width: 100%;
    @include desktop {
      width: 960px; }
    @include from(1200px) {
      width: 1080px; }
    @include from(1400px) {
      width: 1140px; } }
  @include desktop {
    border-radius: 3px 3px 0 0;
    &.affix,
    &.affix-bottom {
      border-radius: 0; } } }


.breadcrumb-toggle {
  display: block;
  float: left;
  height: 40px;
  padding: 15px 13px 0 12px;
  position: relative;
  width: 41px;
  &:before,
  &:after {
    @include overlay;
    background: linear-gradient(rgba(black, 0), rgba(black, .1));
    content: "";
    display: block;
    left: auto;
    width: 1px; }
  &:before {
    right: 1px; }
  &:after {
    background: linear-gradient(rgba(white, 0), white); }
  span {
    background: #b5b188;
    box-shadow: 0 1px 0 white;
    display: block;
    height: 1px;
    & + span {
      margin-top: 4px; } } }

.breadcrumb-section {
  color: #b5b188;
  float: left;
  height: 40px;
  line-height: 30px;
  padding: 5px 10px;
  text-transform: capitalize;
  .fa {
    @include fa(21px, 30px);
    float: left;
    margin: 1px 5px 0 0; }
  .fa-globe {
    color: $web; }
  .fa-html5 {
    color: $html5; }
  .fa-css3 {
    color: $css3; }
  .fa-rocket {
    color: $sass; } }

.breadcrumb-next {
  display: block;
  float: right;
  height: 40px;
  overflow: hidden;
  padding-left: 1px;
  position: relative;
  text-align: center;
  width: 41px;
  span {
    display: block;
    position: absolute;
    text-indent: -290486px; }
  .fa {
    @include fa(21px, 40px);
    color: #b5b188; }
  &:before,
  &:after {
    @include overlay;
    background: linear-gradient(rgba(black, 0), rgba(black, .1));
    content: "";
    display: block;
    right: auto;
    width: 1px; }
  &:after {
    background: linear-gradient(rgba(white, 0), white);
    left: 1px; } }

.breadcrumb-previous {
  @extend .breadcrumb-next; }

.menu {
  background: $background;
  display: none;
  li {
    display: block; }
  a {
    display: block; } }

.pagination {
  @include clearfix;
  background: $background;
  box-shadow: inset 0 2px 3px rgba(black, .05), inset 0 1px 0 rgba(black, .05);
  padding: 30px; }

%pagination-link {
  display: block;
  font-size: 20px;
  height: auto;
  padding: 15px 20px 18px;
  text-align: left;
  em {
    color: inherit;
    display: block;
    font-size: .75em;
    opacity: .5; }
  & > strong {
    display: block;
    margin-top: 2px; } }

.pagination-start {
  @extend %button--grey;
  @extend %pagination-link;
  border: none;
  box-shadow: inset 0 1px 0 0 rgba(white, .3), 0 3px 5px rgba(black, .2);
  font-size: 20px;
  padding: 20px 25px;
  strong {
    padding-bottom: 3px; }
  @include desktop {
    border-radius: 5px;
    font-size: 24px;
    padding: 25px 30px;
    strong {
      margin-top: 5px; } } }

.pagination-previous {
  @extend %button--secondary;
  @extend %pagination-link;
  @include mobile {
    display: none; }
  @include desktop {
    float: left;
    width: 33.3333%; } }

.pagination-next {
  @extend %button--primary;
  @extend %pagination-link;
  @include desktop {
    float: right;
    width: 33.3333%; } }

.elevator {
  @extend %button--dark;
  display: block;
  font-size: 16px;
  height: auto;
  line-height: 24px;
  margin: 15px;
  padding: 20px;
  text-align: center;
  @include desktop {
    bottom: 0;
    left: 0;
    position: fixed;
    .fa {
      left: 50%;
      margin-left: -12px; }
    span {
      display: none; } } }

.notification,
.mm-message {
  border-radius: $radius;
  color: white;
  margin-top: 10px;
  padding: 10px 15px;
  &.is-danger {
    background: $dark; }
  &.is-success {
    background: $green; } }

.mm-message {
  background: $green;
  &:before {
    content: "Thank you!";
    display: block;
    font-weight: bold; } }

.content {
  padding: 30px;
  @include desktop {
    font-size: 20px;
    padding: 60px; }
  a {
    text-decoration: underline; }
  code {
    background-color: #222;
    border-radius: 3px;
    color: #66d9ef;
    font-size: $small;
    padding: 1px 5px 3px;
    position: relative;
    top: -1px; }
  & > dl {
    background: $background;
    color: $text;
    font-size: $small;
    dt {
      font-weight: 700;
      padding: 15px;
      padding-bottom: 0; }
    dd {
      padding: 15px;
      padding-top: 0;
      & + dt {
        border-top: 1px solid white; } } }
  h3 {
    color: $strong;
    font-size: 32px;
    font-weight: 300;
    line-height: 1;
    margin: 30px 0;
    padding-top: 30px;
    position: relative;
    top: 1px;
    a {
      font-weight: 300;
      display: block;
      height: 30px;
      line-height: 30px;
      position: absolute;
      right: 100%;
      text-align: center;
      text-decoration: none;
      top: 30px;
      width: 30px;
      &:hover {
        color: $strong; } }
    &:first-child {
      margin-top: 0;
      padding-top: 0;
      a {
        top: 0; } }
    @include desktop {
      a {
        background: rgba(white, 0.86);
        border-radius: 25px;
        box-shadow: 0 1px 2px rgba(black, .2);
        height: 50px;
        line-height: 50px;
        margin-right: 35px;
        top: 25px;
        width: 50px;
        &:hover {
          background: white; } }
      &:first-child {
        a {
          top: -5px; } } } }
  h4 {
    border-bottom: 1px solid $border;
    color: $strong;
    font-size: 20px;
    font-weight: 700;
    line-height: 1;
    margin: 30px 0;
    padding-bottom: 15px; }
  p {
    margin: 1em 0;
    &:first-child {
      margin-top: 0; }
    &:last-child {
      margin-bottom: 0; } }
  pre {
    line-height: 1.25;
    position: relative; }
  li + li {
    margin-top: 5px; }
  ul {
    list-style: disc outside;
    margin: 15px 0;
    padding-left: 1.5em;
    ul {
      list-style-type: circle;
      ul {
        list-style-type: square; } }
    &.files {
      font-family: $family-monospace;
      font-weight: 400; }
    &.software {
      @include clear;
      list-style: none;
      padding-left: 0;
      text-align: center;
      li {
        float: left;
        margin-bottom: 15px;
        width: 50%;
        img {
          max-height: 64px; }
        strong {
          display: block;
          line-height: 1;
          margin-top: 5px; }
        @include desktop {
          margin: 0;
          width: 20%; } } } }
  figure.highlight {
    margin: 30px -30px;
    overflow: auto;
    pre code {
      @include label;
      background: inherit;
      color: inherit;
      display: block;
      padding: 30px;
      top: 0;
      &.language-html {
        @include label("HTML", $html5, white); }
      &.language-css {
        @include label("CSS", $css3, white); }
      &.language-scss {
        @include label("SCSS", $sass, white);
        &.language-css {
          @include label("SCSS CSS", $sass, white); } }
      @include desktop {
        padding: 30px 60px; } }
    @include desktop {
      margin: 30px -60px; } }
  .answer,
  .important,
  .info {
    background: rgba($blue, 0.1);
    border-left: 0.25em solid $blue;
    color: rgba(black, 0.5);
    padding: 15px 20px;
    @include desktop {
      padding: 25px 30px; } }
  .answer {
    background: rgba($green, 0.1);
    border-left-color: $green;
    .question {
      color: $dark;
      display: block;
      font-weight: 700;
      + br {
        display: none; } } }
  .important {
    background: rgba($red, 0.1);
    border-left-color: $red; }
  .result {
    @include label("Result");
    border: 1px solid $yellow;
    color: black;
    margin: 30px 0;
    padding: 15px;
    &:before {
      top: -15px; } }
  .table {
    margin: 30px -30px;
    overflow: auto;
    table {
      background: #fafafa;
      font-size: $small;
      width: 100%;
      th,
      td {
        border: 1px solid #ccc;
        padding: 0.5em 1em;
        vertical-align: top;
        &.empty {
          background: white;
          border: none; }
        &.no {
          color: $red; }
        &.yes span {
          background: $green;
          color: white;
          padding: 2px 5px; } }
      th {
        background: #eee;
        font-weight: 400;
        text-align: left;
        white-space: nowrap;
        strong {
          display: block; } }
      code {
        display: inline-block;
        margin-bottom: 5px;
        vertical-align: top; }
      pre {
        margin: 0;
        padding: 0;
        &:before {
          display: none; } } }
    @include desktop {
      margin: 30px 0; } }
  .footnotes {
    border-top: 1px solid $border;
    font-size: $small;
    margin-top: 1em;
    padding: 1em 1em 0; } }

$carbon-space: 15px;

#carbon {
  background: white;
  border-radius: 5px;
  box-shadow: 0 10px 20px rgba(black, .2);
  font-size: 14px;
  padding: $carbon-space; }

#carbonads {
  text-align: left;
  text-shadow: none;
  a:hover {
    text-decoration: underline; }
  span {
    display: block; }
  .carbon-img {
    display: block;
    height: 100px;
    width: 130px;
    img {
      display: block; } }
  .carbon-text {
    display: block;
    color: $strong;
    margin-bottom: 5px; }
  .carbon-poweredby {
    font-size: $small; } }

@include until(1099px) {
  #carbon {
    margin-left: auto;
    margin-right: auto;
    max-width: 340px;
    min-height: 100px + ($carbon-space * 2);
    min-width: 300px; }
  #carbonads {
    .carbon-img {
      float: left; }
    .carbon-text {
      margin-left: 130px + $carbon-space; }
    .carbon-poweredby {
      margin-left: $carbon-space; } } }

@include from(1100px) {
  #carbon {
    bottom: 15px;
    position: fixed;
    right: 15px;
    width: 160px; }
  #carbonads {
    .carbon-img {
      margin-bottom: 10px; } } }
