$hero-1: #f4a35b;
$hero-2: #f4655b;
$hero-3: #d16098;
$hero-4: #ca3469;

$web: #6ecd56;
$html5: #f16529;
$css3: #33a9dc;
$sass: #c69;
$fortyfour: #5f45bb;
$beige: #ffedd7;

$blue: $css3;
$green: $web;
$red: crimson;
$yellow: #f4f1ce;

$background: #f5f7f9;
$border: #ebeff5;
$border-dark: #d3d6db;
$dark: #273032;
$link: $hero-3;
$visited: #aeb1b5;
$text: #69707a;
$light: #aeb1b5;
$strong: #222325;

$radius: 3px;
$small: 0.875em;

$family-sans-serif: "Source Sans Pro", "Roboto", "Open Sans", Arial, sans-serif;
$family-monospace: "Source Code Pro", "Inconsolata", monospace;

$height-header: 69px;

$shadow-black: 0 1px 1px rgba(black, .2);
$shadow-black-inset: inset 0 1px 2px rgba(black, .2);
$shadow-inset: 0 1px 0 0 white;

.page-close {
  z-index: 2002; }

.page-menu {
  z-index: 2001; }

.page-overlay {
  z-index: 2000; }

.breadcrumb.affix {
  z-index: 1986; }

#carbon {
  z-index: 86; }

.elevator {
  z-index: 29; }
