html {
  // +scrollbar(5px, 10px)
  background: $link;
  font-size: 16px;
  min-width: 320px;
  overflow: hidden;
  overflow-y: scroll; }

body {
  color: $text;
  @include display(flex);
  @include flex-direction(column);
  font-size: 1rem;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
  line-height: 1.4;
  min-height: 100vh;
  position: relative;
  text-rendering: optimizeLegibility; }

a {
  color: $link;
  cursor: pointer;
  text-decoration: none;
  &:visited {
    color: $visited; } }

input[type="checkbox"] {
  vertical-align: baseline; }

strong {
  color: $strong;
  font-weight: 600; }

pre {
  white-space: pre;
  word-wrap: normal; }

body,
button,
input,
textarea {
  font-family: $family-sans-serif; }

code,
pre {
  font-family: $family-monospace;
  font-weight: 400; }
