%button {
  @include control($background, $border, $text);
  box-shadow: $shadow-black;
  cursor: pointer;
  height: auto;
  line-height: 1;
  padding: 8px 12px 10px;
  text-align: left;
  .fa {
    @include fa(21px, 24px);
    left: 20px;
    margin-top: -12px;
    position: absolute;
    top: 50%; }
  strong {
    color: inherit; }
  &:active,
  &:focus {
    box-shadow: $shadow-black-inset; } }

@mixin button($color, $text: white, $is-light: false) {
  @extend %button;
  background: linear-gradient(saturate(lighten($color, 10%), 10%), $color);
  border-color: darken($color, 10%);
  color: $text;
  @if $is-light {
    box-shadow: inset 0 1px 0 0 rgba(white, .3), 0 1px 2px rgba(black, .05);
    text-shadow: 0 1px 1px white; }
  @else {
    box-shadow: inset 0 1px 0 0 rgba(white, .3), 0 1px 2px rgba(black, .3);
    text-shadow: 0 1px 1px rgba(black, .2); }
  &:hover {
    background: linear-gradient(saturate(lighten($color, 15%), 15%), $color);
    border-color: darken($color, 20%);
    color: $text; }
  &:active,
  &:focus {
    background: $color; } }

%button--primary {
  @include button($link); }

%button--secondary {
  @include button($background, $text, true); }

%button--grey {
  @include button($border-dark, $text, true); }

%button--border {
  @include button($border, $text, true); }

%button--dark {
  @include button($dark); }
