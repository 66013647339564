@mixin clear {
  &:after {
    clear: both;
    content: " ";
    display: table; } }

@mixin center($size) {
  left: 50%;
  margin-left: -($size / 2);
  margin-top: -($size / 2);
  position: absolute;
  top: 50%; }

@mixin fa($size, $dimensions) {
  display: block;
  font-size: $size;
  height: $dimensions;
  line-height: $dimensions;
  text-align: center;
  width: $dimensions; }

@mixin label($text: "Code", $background: $yellow, $color: rgba(black, 0.5)) {
  position: relative;
  &:before {
    background: $background;
    color: $color;
    content: $text;
    display: inline-block;
    font-family: $family-sans-serif;
    font-size: 0.6rem;
    font-weight: 700;
    height: 1rem;
    left: -1px;
    letter-spacing: 0.1em;
    line-height: 1rem;
    padding: 0 0.5em;
    position: absolute;
    text-transform: uppercase;
    top: 0; } }

@mixin overlay($offset: 0) {
  bottom: $offset;
  left: $offset;
  position: absolute;
  right: $offset;
  top: $offset; }

@mixin replace($bg, $w, $h) {
  background: $bg center center no-repeat;
  background-size: $w $h;
  display: block;
  height: $h;
  outline: none;
  overflow: hidden;
  text-indent: -290486px;
  width: $w; }

@mixin from($device) {
  @media screen and (min-width: $device) {
    @content; } }

@mixin until($device) {
  @media screen and (max-width: $device) {
    @content; } }

@mixin mobile {
  @media screen and (max-width: 959px) {
    @content; } }

@mixin desktop {
  @media screen and (min-width: 960px) {
    @content; } }
