@charset "UTF-8";

/// Programatically determines whether a color is light or dark.
///
/// @link https://robots.thoughtbot.com/closer-look-color-lightness
///
/// @param {Color (Hex)} $color
///
/// @example scss - Usage
///   is-light($color)
///
/// @return {Bool}

@function is-light($hex-color) {
  $-local-red: red(rgba($hex-color, 1));
  $-local-green: green(rgba($hex-color, 1));
  $-local-blue: blue(rgba($hex-color, 1));
  $-local-lightness: ($-local-red * 0.2126 + $-local-green * 0.7152 + $-local-blue * 0.0722) / 255;

  @return $-local-lightness > 0.6;
}
